<template>
  <b-card
      class="brandreward-edit-wrapper"
  >
    <!-- form -->
    <b-form id="brandrewardForm" class="edit-form mt-2">

      <!-- 基本信息 -->
      <b-card header="当前信息">
        <b-row>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="品牌档案ID"
                label-for="archive_id"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="archive_id"
                  size="sm"
                  v-model="brandreward.archive_id"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="期初预充"
                label-for="recharge_begin"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="recharge_begin"
                  size="sm"
                  v-model="brandreward.recharge_begin"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充额度"
                label-for="recharge_amount"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="recharge_amount"
                  size="sm"
                  v-model="brandreward.recharge_amount"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充已用"
                label-for="recharge_used"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="recharge_used"
                  size="sm"
                  v-model="brandreward.recharge_used"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充剩余"
                label-for="recharge_remain"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="recharge_remain"
                  size="sm"
                  v-model="brandreward.recharge_remain"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="期初返利"
                label-for="return_begin"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="return_begin"
                  size="sm"
                  v-model="brandreward.return_begin"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利额度"
                label-for="return_amount"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="return_amount"
                  size="sm"
                  v-model="brandreward.return_amount"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利已用"
                label-for="return_used"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="return_used"
                  size="sm"
                  v-model="brandreward.return_used"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利剩余"
                label-for="return_remain"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="return_remain"
                  size="sm"
                  v-model="brandreward.return_remain"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="合计剩余"
                label-for="total_remain"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="total_remain"
                  size="sm"
                  v-model="brandreward.total_remain"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="save"
            >
              保存
            </b-button>
            <b-button
                variant="outline-secondary"
                @click="cancel"
            >
              取消
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import brandrewardStore from './brandrewardStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      brandreward: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('brandreward')) store.registerModule('brandreward', brandrewardStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandreward')) store.unregisterModule('brandreward')
    })

    const edit = function () {
      store.dispatch('brandreward/edit', {id: this.id}).then(res => {
        this.brandreward = res.data.data
      })
    }

    const view = function () {
      store.dispatch('brandreward/view', {id: this.id}).then(res => {
        this.brandreward = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      store.dispatch('brandreward/save', this.brandreward).then(res => {
        toast.success('数据已保存!')
        this.$router.push({name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
